<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <view-intro class="text-start" :heading="membership.name ? membership.name :'' "/>
    <div>
      <v-btn
        color="primary"
        elevation="2"
        outlined
        @click="$router.go(-1)"
      >&lArr; Terug
      </v-btn>
    </div>
    <div v-if="loading" class="d-flex justify-content-center" style="margin-top: 30vh;">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <material-card
        class="mt-10 elevation-1"
        icon="fa-regular fa-credit-card-blank"
        icon-smalld
        title="Abonnement informatie"
        color="accent"
      >
        <v-card-text>
          <v-alert
            outlined
            shaped
            text
            type="success"
            v-if="transaction.paid"
          >Deze bestelling is betaald
          </v-alert>
          <v-alert
            v-else
            outlined
            shaped
            text
            type="error"
          >Deze bestelling is nog niet betaald
          </v-alert>
          <v-alert
            outlined
            shaped
            text
            type="success"
            v-if="membership.currentStatus === 'active'"
          >Dit abonnement is actief
          </v-alert>
          <v-alert
            outlined
            shaped
            text
            v-else-if="membership.currentStatus === 'finished'"
            type="error"
          >Dit abonnement is afgelopen
          </v-alert>
          <v-alert
            outlined
            shaped
            text
            v-else-if="membership.currentStatus === 'stillNotActive'"
          >Dit abonnement is nog niet geactiveerd
          </v-alert>
          <div style="display: flex; gap:15px; flex-wrap: wrap">
            <v-card
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="mb-2" style="display: flex; align-items: center;">
                    <i class="fa-regular fa-user mr-3 fa-2x"></i>
                    <div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ user.user.name }}
                      </v-list-item-title>
                      <div class="d-flex flex-column">
                        <a :href="`mailto:${user.user.email}`" class="text-overline mb-2">
                          {{ user.user.email }}
                        </a>
                        <a v-if="user.user.phoneNumber" :href="`tel:${user.user.phoneNumber}`" class="text-overline">
                          +31 {{ user.user.phoneNumber }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <v-list-item-subtitle> {{ user.address.formatted }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-btn
                  outlined
                  rounded
                  text
                  :to="`/users/${user.uid}`"
                  class="pl-4 pr-4"
                >
                  <i class="fa-regular fa-up-right-from-square mr-3 fa-1x"></i>
                  Gebruiker bekijken
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1" style="display: flex; align-items: center">
                    <i class="fa-regular fa-credit-card-blank mr-3 fa-2x"></i>
                    Doorloopende abbonoment
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ membership.presentage }}% korting, geldig voor {{ membership.validForDays }} dagen
                    <p v-if="membership.currentStatus !== 'stillNotActive'">
                      Geldig van {{ membership.startDate }} tot {{ membership.endDate }}
                    </p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
        </v-card-text>
      </material-card>
      <div class="mt-4">
        <v-btn
          color="primary"
          elevation="2"
          outlined
          @click="$router.go(-1)"
        >&lArr; Terug
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'SubscriptionDetail',
    data () {
      const { subscriptionId } = this.$route.params
      if (!subscriptionId) return this.$router.push('/subscriptions')
      this.$auth.getMemberschip(subscriptionId).then(async membership => {
        if (!membership) {
          this.$swal({
            title: 'Abonnement is niet gevonden',
            icon: 'error',
          })
        }

        this.membership = membership
        this.transaction = await this.$auth.getTransactionStatus(membership.transactionId)
        this.$auth.getStoreUser(membership.userUid).then(user => {
          this.user = user
        }).finally(() => {
          this.loading = false
        })
      }).catch((err) => {
        this.alert.visable = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      })
      return {
        transaction: null,
        user: {},
        membership: {},
        loading: true,
        alert: {
          visible: false,
          description: '',
          button: {
            visible: false,
            text: null,
            cl: null,
          },
        },
      }
    },
  }
</script>

<style scoped>

</style>
